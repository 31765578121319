$(document).ready(function () {
  $(".slide-banner__slide-wrapper").slick({
    slidesToShow: 1,
    arrows: false,
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
  });
});
