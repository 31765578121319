$(document).ready(function () {
  $("#slickNews").slick({
    slidesToShow: 3,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
  // Custom carousel nav

  // Custom carousel nav
  $(".latest-news__arrow--prev").click(function () {
    $(this).parents().find(".slick-slider").slick("slickPrev");
  });

  $(".latest-news__arrow--next").click(function (e) {
    e.preventDefault();
    $(this).parents().find(".slick-slider").slick("slickNext");
  });
});
