$(document).ready(function () {
  $("#text-with-image__slick-wrapper").slick({
    slidesToShow: 1,
    arrows: false,
    dots: false,
  });

  // Custom carousel nav
  $(".text-with-image__arrow--prev").click(function () {
    $(this).parents().find(".slick-slider").slick("slickPrev");
  });

  $(".text-with-image__arrow--next").click(function (e) {
    e.preventDefault();
    $(this).parents().find(".slick-slider").slick("slickNext");
  });
});
